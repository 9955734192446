<template>
  <div>Start</div>
</template>
<script>
export default {
  mounted() {
    console.log("start");
    this.$router.push("/login");
  },
};
</script>
<style lang="scss" scoped></style>
